<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body" style="height: 50px">
          <a-button style="float: right" @click="gotoList" type="default"
            >专项资金支付列表</a-button
          >
        </div>
      </div>

      <div
        :style="{
          padding: '10px',
          width: modal ? '100%' : '80%',
          margin: '0px auto',
        }"
      >
        <a-form-model
          ref="specialexpenditureform"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <table style="width: 100%">
            <tr>
              <td
                colspan="3"
                style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                "
              >
                河北省教育科学研究院专项资金支付申请表
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item
                  label="项目名称"
                  :labelCol="{ span: 6 }"
                  :wrapperCol="{ span: 17 }"
                  prop="xiangmu"
                  style="margin-bottom: 0px"
                >
                  <a-input-search
                    class="select_search"
                    placeholder="请选择"
                    readOnly
                    v-model="form.xiangmu"
                    @click="onSearchxiangmu(form)"
                    @search="onSearchxiangmu(form)"
                  >
                    <a-button
                      type="primary"
                      slot="enterButton"
                      icon="plus"
                      title="添加"
                    />
                  </a-input-search>
                </a-form-model-item>
              </td>

              <td>
                <a-form-model-item
                  label="时间"
                  :labelCol="{ span: 6 }"
                  :wrapperCol="{ span: 17 }"
                  prop="reportTime"
                  style="margin-bottom: 0px"
                >
                  <a-date-picker v-model="form.reportTime" />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item
                  label="类型"
                  :labelCol="{ span: 6 }"
                  :wrapperCol="{ span: 17 }"
                  prop="type"
                  style="margin-bottom: 0px"
                >
                  <a-select v-model="form.type" disabled="disabled">
                    <a-select-option value="科研">科研</a-select-option>
                    <a-select-option value="教研">教研</a-select-option>
                  </a-select>
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px">
                <table class="formtable">
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>子项目名称
                    </td>
                    <td style="text-align: left" colspan="3">
                      <a-form-model-item
                        prop="zixiangmu"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.zixiangmu" />
                      </a-form-model-item>
                    </td>
                    <td><span style="color: red">*</span>项目负责人</td>
                    <td style="text-align: left">
                      <a-form-model-item
                        label=""
                        prop="fuzeren"
                        style="margin-bottom: 0px"
                      >
                        <a-input
                          placeholder="请选择项目"
                          readOnly
                          v-model="form.fuzeren"
                        />
                      </a-form-model-item>
                    </td>
                    <!-- <td>项目组申请人</td>
                    <td style="text-align: left">
                      <a-form-model-item label="" style="margin-bottom: 0px">
                        <a-input v-model="form.xiangmuzushenqingren" />
                      </a-form-model-item>
                    </td> -->
                  </tr>
                  <tr>
                    <td style="width: 100px">
                      <span style="color: red">*</span>项目起止年月
                    </td>
                    <td style="text-align: left" colspan="3">
                      <a-form-model-item
                        prop="range"
                        style="margin-bottom: 0px"
                      >
                        <a-range-picker v-model="form.range" />
                      </a-form-model-item>
                    </td>
                    <td><span style="color: red">*</span>项目研究阶段</td>
                    <td style="text-align: left">
                      <a-form-model-item
                        prop="jieduan"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.jieduan" />
                      </a-form-model-item>
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="2">
                      <span style="color: red">*</span>本次申请支付
                    </td>
                    <td><span style="color: red">*</span>金额（元）</td>
                    <td colspan="4" style="text-align: left">
                      <a-form-model-item
                        label=""
                        prop="amount"
                        style="margin-bottom: 0px"
                      >
                        <a-input
                          v-model="form.amount"
                          readOnly
                          style="width: 200px"
                        />
                      </a-form-model-item>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="5" style="padding: 0px">
                      <a-row>
                        <a-col
                          :span="8"
                          style="
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >列支科目</a-col
                        >
                        <a-col
                          :span="4"
                          style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >金额（元）</a-col
                        >
                        <a-col
                          :span="8"
                          style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >列支科目</a-col
                        >
                        <a-col
                          :span="4"
                          style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          "
                          >金额（元）</a-col
                        >
                      </a-row>
                      <a-row>
                        <template v-for="(item, index) in form.items">
                          <a-col
                            :span="8"
                            :style="{
                              borderLeft:
                                index % 2 == 0 ? 'none' : 'solid 1px #cccccc',
                              borderBottom: 'solid 1px #cccccc',
                              height: '50px',
                              padding: '5px',
                            }"
                            :key="`${index}_1`"
                          >
                            <a-select v-model="item.kemu" allowClear>
                              <a-select-opt-group
                                v-for="group in xiangmuDataSource"
                                :key="group.id"
                              >
                                <span
                                  slot="label"
                                  style="color: #0000cc; font-size: 16px"
                                >
                                  {{ group.itemValue }}
                                </span>
                                <a-select-option
                                  v-for="opt in group.children"
                                  :key="opt.id"
                                  :value="opt.itemValue"
                                  >{{ opt.itemValue }}</a-select-option
                                >
                              </a-select-opt-group>
                            </a-select>
                          </a-col>
                          <a-col
                            :span="4"
                            :style="{
                              borderLeft: 'solid 1px #cccccc',
                              borderBottom: 'solid 1px #cccccc',
                              height: '50px',
                              padding: '5px',
                            }"
                            :key="`${index}_2`"
                          >
                            <a-input-number
                              v-model="item.itemAmount"
                              @change="itemAmountChange"
                            />
                          </a-col>
                        </template>
                      </a-row>
                    </td>
                  </tr>
                  <tr>
                    <td><span style="color: red">*</span>支出明细说明</td>
                    <td colspan="5" style="text-align: left">
                      <a-form-model-item
                        prop="detail"
                        style="margin-bottom: 0px"
                      >
                        <a-input v-model="form.detail" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>附件</td>
                    <td colspan="5" style="text-align: left">
                      <a-form-model-item style="margin-bottom: 0px">
                        <a-upload
                          :action="uploadFileUrl"
                          :fileList="fileList"
                          @change="handleFileChange"
                          @preview="showWpsRead"
                          :remove="() => upLoadRemove"
                        >
                          <div
                            v-if="
                              this.form.status == 0 || this.form.status == 3
                            "
                          >
                            <a-button
                              ><a-icon type="upload" />上传文件</a-button
                            >
                          </div>
                        </a-upload>
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>经办人</td>
                    <td>
                      <a-form-model-item
                        label=""
                        style="margin-bottom: 0px"
                        v-if="form.status == 0 || form.status == 3"
                      >
                        <a-input
                          readOnly
                          v-model="form.creatorName"
                          style="width: 100%"
                        />
                      </a-form-model-item>
                      <div style="text-align: right" v-else>
                        <img
                          style="width: 120px"
                          :src="form.creatorPic"
                          v-if="form.creatorPic"
                        />
                        <span v-else>{{ form.creatorName }} </span>
                        <div>
                          {{ form.reportTime.format("YYYY年MM月DD日") }}
                        </div>
                      </div>
                    </td>
                    <td style="width: 250px">项目负责人意见</td>
                    <td style="width: 250px">
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="XiangMuFuZheRen"
                        @callback="auditCb"
                      />
                    </td>
                    <td>项目管理部门负责人意见</td>
                    <td style="width: 250px">
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BuShiFuZeRen"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>财务意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="CaiWu"
                        @callback="auditCb"
                      />
                    </td>
                    <td>办公室财务副主任意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiFuZhuRen"
                        @callback="auditCb"
                      />
                    </td>
                    <td>办公室主任意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiZhuRen"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>项目管理部门分管领导意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="FenGuanLingDao"
                        @callback="auditCb"
                      />
                    </td>
                    <td>分管财务院领导意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="BanGongShiFenGuanLingDao"
                        @callback="auditCb"
                      />
                    </td>
                    <td>院长意见</td>
                    <td>
                      <auditPrint
                        :infoData="form"
                        taskPrintKey="YuanChang"
                        @callback="auditCb"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 10px"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger" style="margin-right: 10px"
                  >提交</a-button
                >
              </a-popconfirm>
              <a-button
                type="primary"
                v-if="form.processExecuteStatus"
                style="margin-right: 10px"
                @click="showaudit()"
                >审批</a-button
              >
              <a-button
                v-if="form.status == 2"
                @click="setexpenditure"
                style="margin-right: 10px"
                >票据整理单</a-button
              >
              <a-button
                v-if="form.status == 2"
                @click="showWps"
                style="margin-right: 10px"
                >打印</a-button
              >
              <a-button v-if="modal" @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="审批"
          v-model="auditModal.visible"
          width="40%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <audit
            :info="auditModal.info"
            :formValidPass="formValidPass"
            @callback="auditCallBack"
          />
        </a-modal>
        <a-modal
          title="票据整理单"
          v-model="expenditureModal.visible"
          width="80%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <specialexpenditureExpend
            :info="expenditureModal.info"
            @callback="expenditureModalCallBack"
          />
        </a-modal>
        <a-modal
          title="选择用户"
          v-model="selectuservisible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser
            :column="this.selectusertype"
            @callback="selectuserCallback"
          />
        </a-modal>

        <a-modal
          title="选择专项经费支出预算"
          v-model="searchSPFundModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <searchSPFund @callback="searchSPFundCallback" />
        </a-modal>

        <a-modal
          :visible="showImgModal"
          :footer="false"
          width="80%"
          destroyOnClose
          :maskClosable="false"
          @cancel="showImghandleCancel"
        >
          <iframe
            :src="imgurl"
            frameborder="0"
            width="100%"
            height="800px"
          ></iframe>
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import specialexpenditureApi from "@/api/work/specialexpenditure";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import expenditure from "./expenditure.vue";
import pinyin from "js-pinyin";
import searchSPFund from "../components/searchSPFund.vue";
import dictionaryItemApi from "@/api/system/dictionaryItem";
import request from "@/request";
import specialfundApi from "@/api/work/specialfund";
import specialexpenditureExpend from "./specialexpenditureExpend.vue";
const columns = [
  {
    align: "center",
    dataIndex: "xiangmu",
    title: "列支科目",
    scopedSlots: { customRender: "textbox" },
  },
  {
    align: "center",
    dataIndex: "amount",
    title: "金额",
    scopedSlots: { customRender: "number" },
  },
  {
    align: "center",
    dataIndex: "remark",
    title: "使用资金的理由及与项目的关系",
    scopedSlots: { customRender: "textbox" },
  },
];
export default {
  name: "specialexpenditure",
  data() {
    return {
      imgurl: "",
      showImgModal: false,
      uploadFileUrl: "",
      fileList: [],
      upLoadRemove: false,
      form: {
        specialFundId: null,
        xiangmu: "",
        reportTime: null,
        type: "",
        fuzerenId: "",
        fuzeren: "",
        jieduan: "",
        status: 0,
        items: [{}, {}, {}, {}, {}, {}],
      },
      columns,
      labelCol: { span: 1 },
      wrapperCol: { span: 22 },
      orgDates: [],
      xiangmuDataSource: [],
      orgs: [],
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
        type: 0,
      },
      searchSPFundModal: {
        visible: false,
      },
      selectuservisible: false,
      selectusertype: "",
      extensionSetting: {},
      expenditureModal: {
        visible: false,
        info: null,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      rules: {
        xiangmu: [
          {
            required: true,
            message: "请选择项目",
            trigger: "change",
          },
        ],
        reportTime: [
          {
            required: true,
            message: "请选择编报时间",
            trigger: "change",
          },
        ],
        zixiangmu: [
          {
            required: true,
            message: "请输入子项目名称",
            trigger: "blur",
          },
        ],
        range: [
          {
            required: true,
            message: "请选择项目起止年月",
            trigger: "change",
          },
        ],
        jieduan: [
          {
            required: true,
            message: "请输入项目研究阶段",
            trigger: "blur",
          },
        ],
        detail: [
          {
            required: true,
            message: "请输入支出明细说明",
            trigger: "blur",
          },
        ],
      },
      formValidPass: true,
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  props: {
    info: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    selectuser,
    expenditure,
    searchSPFund,
    specialexpenditureExpend,
  },
  mounted() {
    this.getOrgTree();
    this.getXiangmuDataSource();
    this.uploadFileUrl = request.getUploadUrl("document");
    if (this.info && this.info.id > 0) {
      this.getData();
    } else {
      this.form.reportTime = this.$moment();
      this.upLoadRemove = true;
    }
  },
  methods: {
    itemAmountChange(v) {
      let amount = 0;
      this.form.items.forEach((a) => {
        if (!isNaN(parseFloat(a.itemAmount)))
          amount = amount + parseFloat(a.itemAmount);
      });
      this.form.amount = amount;
    },
    showImghandleCancel() {
      this.showImgModal = false;
    },
    auditCb(data) {
      this.$emit("callback", data != null);
      this.getData();
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    getXiangmuDataSource() {
      dictionaryItemApi.getItemByDictionaryName("列支科目").then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          let data = res.data;
          let groups = data.filter((a) => a.parentId == 0);
          groups.forEach((e, i) => {
            e.children = data.filter((a) => a.parentId == e.id);
          });
          this.xiangmuDataSource = groups;
          this.xiangmuDataSourceBack = groups;
        }
      });
    },
    setexpenditure() {
      this.expenditureModal.visible = true;
      this.expenditureModal.info = this.form;
    },
    expenditureModalCallBack(data) {
      if (data) {
        this.form.items[this.expenditureModal.index] = data;
      }

      this.expenditureModal.visible = false;
    },
    gotoList() {
      this.$router.push({ path: "/business/specialexpenditurelist" });
    },
    async showaudit() {
      let validRes = await this.$refs.specialexpenditureform.validate();
      if (!validRes) {
        this.$message.error("请填写必填项");
        return;
      }
      this.formValidPass = validRes;
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel) {
        close = false;
        let validRes = await this.$refs.specialexpenditureform.validate();
        if (!validRes) {
          return false;
        }
        this.loading = true;
        this.loadingTip = "保存中...";
        let formData = {
          id: this.info.id,
          specialFundId: this.form.specialFundId,
          xiangmu: this.form.xiangmu,
          type: this.form.type,
          fuzeren: this.form.fuzeren,
          fuzerenId: this.form.fuzerenId,
          zixiangmu: this.form.zixiangmu,
          jieduan: this.form.jieduan,
          amount: this.form.amount,
          items: this.form.items,
          detail: this.form.detail,
          updateOnly: true,
        };
        if (this.form.range && this.form.range.length == 2) {
          formData.startTime = this.form.range[0].format("YYYY-MM-DD");
          formData.endTime = this.form.range[1].format("YYYY-MM-DD");
        }
        let res = await specialexpenditureApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(record, type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = record;
      this.selectuseModal.type = type;
    },
    onSearchxiangmu() {
      this.searchSPFundModal.visible = true;
    },

    onSearchUser(column) {
      this.selectusertype = column;
      this.selectuservisible = true;
    },
    selectuserCallback(user, column) {
      this.form.fuzerenId = user.userName;
      this.form.fuzeren = user.realName;
      this.selectuservisible = false;
    },
    searchSPFundCallback(item) {
      this.form.xiangmu = item.xiangmu;
      this.form.type = item.type;
      this.form.fuzerenId = item.fuzerenId;
      this.form.fuzeren = item.fuzeren;
      this.form.specialFundId = item.id;
      this.searchSPFundModal.visible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgDates = res.data.tree[0].children;
          res.data.tree[0].orgsNumber = this.orgsNumber;
          this.orgsNumber = this.orgsNumber++;
          this.orgs = res.data.tree;
          if (!this.data) {
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
                this.form.orgId = find[0].id;
              } else {
                tree = find[0].children;
              }
            }
          }
          this.form.creatorName = res.data.realName;
        }
      });
    },
    getData() {
      this.loading = true;
      specialexpenditureApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.reportTime = this.$moment(res.data.reportTime, "YYYY-MM-DD");
          if (res.data.startTime) {
            res.data.range = [
              this.$moment(res.data.startTime, "YYYY-MM-DD"),
              this.$moment(res.data.endTime, "YYYY-MM-DD"),
            ];
          }
          this.form = { ...res.data };
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (res.data.files) {
            var d = JSON.parse(res.data.files);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }
            if (res.data.status == 0 || res.data.status == 3)
              this.upLoadRemove = true;
            this.fileList = files;
          }
        }
      });
    },
    handleSubmit(status) {
      if (!this.form.specialFundId) {
        this.$message.error("请选择项目");
        return;
      }
      specialexpenditureApi
        .getSpecialFundUsed({
          specialFundId: this.form.specialFundId,
          id: this.form.id,
        })
        .then((res) => {
          // data
          if (!isNaN(res.data)) {
            let amount = this.form.amount + parseFloat(res.data);
            specialfundApi.getData(this.form.specialFundId).then((r) => {
              if (r.data.amount * 10000 < amount) {
                this.$message.error("超过项目预算");
              } else {
                this.$refs.specialexpenditureform.validate((valid) => {
                  if (valid) {
                    let itemsCheck = this.form.items.filter(
                      (a) => a.kemu && a.kemu != ""
                    );
                    if (itemsCheck.length == 0) {
                      this.$message.error("请填写列支科目");
                      return;
                    }
                    let formData = { ...this.form, status };
                    if (this.info) {
                      formData.id = this.info.id;
                    }
                    delete formData.createTime;
                    delete formData.creator;
                    delete formData.creatorName;
                    formData.reportTime =
                      formData.reportTime.format("YYYY-MM-DD");
                    if (formData.range && formData.range.length == 2) {
                      formData.startTime =
                        formData.range[0].format("YYYY-MM-DD");
                      formData.endTime = formData.range[1].format("YYYY-MM-DD");
                    }
                    delete formData.range;
                    delete formData.auditRecords;
                    let extensionSetting = {};
                    if (formData.fuzerenId) {
                      extensionSetting["node_专项资金支出申请-项目负责人意见"] =
                        {
                          targetUsers: formData.fuzerenId,
                          count: formData.fuzerenId.split(",").length,
                        };
                    }
                    formData.extensionSetting =
                      JSON.stringify(extensionSetting);
                    if (this.fileList.length > 0) {
                      var file = [];
                      this.fileList.forEach((f) => {
                        file.push({
                          title: f.response.title,
                          url: f.response.url,
                        });
                      });
                      formData.files = JSON.stringify(file);
                    }

                    this.loading = true;
                    specialexpenditureApi
                      .addOrUpdate(formData)
                      .then((res) => {
                        if (res.errorCode == this.$commons.RespCode.success) {
                          this.$message.success(res.errorMsg);
                          if (this.modal) {
                            this.$emit("callback", true);
                          } else {
                            this.gotoList();
                          }
                        } else {
                          this.$message.error(res.errorMsg);
                        }
                        this.loading = false;
                      })
                      .catch(() => {
                        this.loading = false;
                      });
                  } else {
                    return false;
                  }
                });
              }
            });
          }
        });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        request
          .isImageUrlValid(file.response.url)
          .then(() => {
            this.imgurl = file.response.url;
            this.showImgModal = true;
          })
          .catch(() => {
            window.open(file.response.url, "_blank");
          });
      } else {
        let fileId =
          "file" +
          file.response.url
            .substring(
              file.response.url.lastIndexOf("/") + 1,
              file.response.url.lastIndexOf(".")
            )
            .replaceAll("-", "");
        let url = file.response.url.substring(
          file.response.url.indexOf("oabucket")
        );
        let type = file.response.url.substring(
          file.response.url.lastIndexOf(".") + 1
        );
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    showWps() {
      let templateId = "specialexpenditure";
      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.range =
        this.$moment(innerData.startTime, "YYYY-MM-DD").format(
          "YYYY年MM月DD日"
        ) +
        " 至 " +
        this.$moment(innerData.endTime, "YYYY-MM-DD").format("YYYY年MM月DD日");
      innerData.reportTime = this.$moment(
        innerData.reportTime,
        "YYYY-MM-DD"
      ).format("YYYY年MM月DD日");

      // 处理申请人图片
      if (innerData.creatorPic != null && innerData.creatorPic != "") {
        innerData.creatorPic =
          "http://localhost:9000/" +
          innerData.creatorPic.substring(innerData.creatorPic.indexOf("uas"));
      }

      innerData.list = [];
      if (this.form.items != null && this.form.items.length > 0) {
        this.form.items.forEach((a) => {
          if (a.kemu != null && a.itemAmount != null) innerData.list.push(a);
        });
        if (innerData.list.length < 6) {
          let size = 6 - innerData.list.length;
          for (var i = 0; i < size; i++) {
            innerData.list.push({
              kemu: "",
              itemAmount: "",
            });
          }
        }
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          let s = a.auditUserSignPicture.indexOf("uas");
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "专项资金支付申请",
          tempUrl: "writeNumberTemp",
        },
      });
      window.open(jump.href, "_blank");
    },
  },
};
</script>
