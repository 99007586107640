<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div :style="{ padding: '10px', margin: '0px auto' }">
        <a-form-model
          ref="loanbillform"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <!-- <div style="width:100%;font-size:40px;text-align:center;padding-bottom:20px">票据整理单</div> -->
          <a-row>
            <a-col :span="7">
              <a-form-model-item
                label="单位"
                prop="org"
                style="margin-bottom: 0px"
              >
                <a-cascader
                  :options="orgs"
                  v-model="form.orgId"
                  @change="orgChanged"
                  :fieldNames="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }"
                  placeholder="请选择部室"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item label="日期" prop="time">
                <a-date-picker v-model="form.time" />
              </a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item
                label="编号"
                prop="number"
                style="margin-bottom: 0px"
              >
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <table style="width: 100%" class="formtable">
            <tr>
              <td style="text-align: left" colspan="4" rowspan="2">
                <a-form-model-item label="内容" style="margin-bottom: 0px">
                  <a-input v-model="form.content" />
                </a-form-model-item>
                <a-form-model-item
                  label="金额(大写)"
                  prop="amount"
                  style="margin-bottom: 0px"
                >
                  <a-input v-model="form.amount" />
                </a-form-model-item>
              </td>
              <td style="width: 150px">付款方式</td>
              <td></td>
            </tr>
            <tr>
              <td>银行付款</td>
              <td></td>
            </tr>
            <tr>
              <td rowspan="2" style="width: 100px">编报部门<br />负责人意见</td>
              <td rowspan="2" style="text-align: left">
                部门复核：<br />
                分管领导:<br />
                主管财务领导:<br />
                单位负责人<br />
              </td>
              <td rowspan="2" style="width: 110px">
                编报部门<br />分管领导意见
              </td>
              <td rowspan="2" style="text-align: left">
                票据审核：<br />
                会计主管：<br />
                核算中心领导：<br />
              </td>
              <td colspan="2">
                <a-row>
                  <a-col
                    :span="24"
                    style="padding-bottom: 15px; font-size: 20px"
                    >结算借款</a-col
                  >
                </a-row>
                <a-row>
                  <a-col :span="24" style="text-align: left">
                    <a-form-model-item
                      label="原借款"
                      style="margin-bottom: 0px"
                    >
                      <a-input-number v-model="form.originAmount" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24" style="text-align: left">
                    <a-form-model-item
                      label="结算后余额"
                      style="margin-bottom: 0px"
                    >
                      <a-input-number v-model="form.lastAmount" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: left">
                <a-form-model-item
                  label="业务经办人"
                  prop="handler"
                  style="margin-bottom: 0px"
                >
                  <a-input-search
                    class="select_search"
                    readOnly
                    placeholder="请选择"
                    v-model="form.handlerName"
                    @click="onSearch"
                    @search="onSearch"
                  >
                    <a-button
                      type="primary"
                      slot="enterButton"
                      icon="plus"
                      title="添加"
                    />
                  </a-input-search>
                </a-form-model-item>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                style="margin-right: 5px"
                @click="handleSubmit"
                >确定</a-button
              >
              <a-button
                type="default"
                style="margin-right: 5px"
                @click="cancelClick"
                >取消</a-button
              >
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";

export default {
  name: "loanbill",
  data() {
    return {
      form: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      orgs: [],
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
      },

      rules: {
        handler: [
          {
            required: true,
            message: "请选择编报人",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.handler == null || this.form.handler == "") {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],

        org: [
          {
            required: true,
            message: "请选择编报单位",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        time: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  components: { selectuser },
  mounted() {
    if (this.info) {
      this.form = { ...this.info };
      this.form.time = this.form.time
        ? this.$moment(this.form.time, "YYYY-MM-DD")
        : this.$moment();
      this.form.orgId = this.form.orgPath;
    } else {
      this.form.time = this.$moment();
    }
    this.getOrgTree();
  },
  methods: {
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = type;
    },
    selectuserCallback(user) {
      this.form[this.selectuseModal.record] = user.userName;
      this.form[this.selectuseModal.record + "Name"] = user.realName;
      this.selectuseModal.visible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info || !this.info.handler) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.handlerName = res.data.realName;
            this.form.handler = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },

    handleSubmit() {
      this.$refs.loanbillform.validate((valid) => {
        if (valid) {
          var data = { ...this.form };
          data.time = data.time.format("YYYY-MM-DD");
          data.orgPath = data.orgId;
          data.orgId = data.orgId[data.orgId.length - 1];

          this.$emit("callback", data);
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback");
    },
  },
};
</script>